import styled, { css } from 'styled-components';
import { MediaPlayer } from '@vidstack/react';

import { getThemeColor, hexToRgba, media } from '@utils/styled';

import { Play } from '@common/components/Icons';

export const MediaPlayerStyled = styled(MediaPlayer)`
  width: 100%;
  height: 100%;
  border: none;

  & > div {
    height: 100%;
  }

  * {
    border-radius: 0;
  }

  video {
    width: 100%;
    height: 100%;
    background-color: ${getThemeColor('bluegrey.95')};
    object-fit: cover;
  }

  img {
    position: absolute;
    border-radius: 0;
  }
`;

export const VideoPlayerStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const PlayPauseControl = styled.button`
  position: absolute;
  right: calc(25px + env(safe-area-inset-right));
  bottom: 20px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses.circle};
  backdrop-filter: blur(10px);
  background-color: ${({ theme }) => hexToRgba(getThemeColor('grey.0')({ theme }), 0.15)};
  cursor: pointer;

  ${({ topAligned }) =>
    topAligned &&
    css`
      ${media.from880down`
          top: 20px;
        `}
    `}
`;

export const PlayStyled = styled(Play)`
  margin-left: 4px;
`;
